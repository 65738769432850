import {
    defineComponent,
    h,
    inject,
    nextTick,
    onMounted,
    provide,
    ref,
    watchEffect,
} from 'vue';

import { loadStripe } from '@stripe/stripe-js';

export const StripeElements = defineComponent({
    name: 'StripeElements',

    props: {
        publishableKey: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            required: true,
        },
    },

    emits: ['update:modelValue'],

    setup(props, { slots, emit }) {
        const stripe = ref(null);
        const elements = ref(null);
        const errors = ref([]);
        const paymentMethodId = ref(props.modelValue);

        onMounted(async () => {
            // Setup stripe using loadStripe
            // loadStripe handles including the stripe.js script tag within the document and loading stripe
            // See https://github.com/stripe/stripe-js
            stripe.value = await loadStripe(props.publishableKey);

            // Create an instance of stripe elements
            elements.value = stripe.value.elements({
                fonts: [
                    {
                        cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap',
                    },
                ],
            });
        });

        const inputElements = ref([]);

        const registerInputElement = (element) => {
            inputElements.value.push(element);
        };

        const createPaymentMethod = async () => {
            const [element] = inputElements.value.filter((element) => {
                return element === 'card' || 'cardNumber';
            });

            const cardElement = elements.value.getElement(element);

            const { paymentMethod, error } =
                await stripe.value.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                });

            if (error) {
                errors.value.push(error);
                return;
            }

            paymentMethodId.value = paymentMethod.id;

            emit('update:modelValue', paymentMethodId);
        };

        const api = {
            elements,
            inputElements,
            errors,
            registerInputElement,
            createPaymentMethod,
            style: {
                base: {
                    fontFamily: 'sans-serif',
                    fontSize: '14px',
                },
            },
        };

        provide('StripeElementsContext', api);

        return () => {
            return h(
                'div',
                {
                    'data-value': paymentMethodId.value,
                },
                slots.default(),
            );
        };
    },
});

export const StripeElementsCard = defineComponent({
    name: 'StripeElementsCard',

    setup(_, { slots }) {
        const api = inject('StripeElementsContext');

        api.registerInputElement('card');

        const card = ref();
        const cardElement = ref();

        watchEffect(() => {
            if (!api.elements.value) {
                return;
            }

            cardElement.value = api.elements.value.create('card', {
                hidePostalCode: true,
                style: api.style,
            });

            cardElement.value.mount(card.value);
        });

        return () => {
            return h('div', {
                ref: card,
            });
        };
    },
});

export const StripeElementsCardNumber = defineComponent({
    name: 'StripeElementsCardNumber',

    setup(_, { slots }) {
        const api = inject('StripeElementsContext');

        api.registerInputElement('cardNumber');

        const cardNumber = ref();
        const cardNumberElement = ref();

        watchEffect(() => {
            if (!api.elements.value) {
                return;
            }

            cardNumberElement.value = api.elements.value.create('cardNumber', {
                style: api.style,
            });

            cardNumberElement.value.mount(cardNumber.value);
        });

        return () => {
            return h('div', {
                ref: cardNumber,
            });
        };
    },
});

export const StripeElementsExpiry = defineComponent({
    name: 'StripeElementsExpiry',

    setup(_, { slots }) {
        const api = inject('StripeElementsContext');

        api.registerInputElement('cardExpiry');

        const cardExpiry = ref();
        const cardExpiryElement = ref();

        watchEffect(() => {
            if (!api.elements.value) {
                return;
            }

            cardExpiryElement.value = api.elements.value.create('cardExpiry', {
                style: api.style,
            });

            cardExpiryElement.value.mount(cardExpiry.value);
        });

        return () => {
            return h('div', {
                ref: cardExpiry,
            });
        };
    },
});

export const StripeElementsCvc = defineComponent({
    name: 'StripeElementsCvc',

    setup(_, { slots }) {
        const api = inject('StripeElementsContext');

        api.registerInputElement('cardCvc');

        const cardCvc = ref();
        const cardCvcElement = ref();

        watchEffect(() => {
            if (!api.elements.value) {
                return;
            }

            cardCvcElement.value = api.elements.value.create('cardCvc', {
                style: api.style,
            });

            cardCvcElement.value.mount(cardCvc.value);
        });

        return () => {
            return h('div', {
                ref: cardCvc,
            });
        };
    },
});

export const StripeElementsButton = defineComponent({
    name: 'StripeElementsButton',

    setup(_, { slots }) {
        const api = inject('StripeElementsContext');

        return () => {
            return h(
                'button',
                {
                    onClick: (e) => {
                        e.preventDefault();
                        api.createPaymentMethod();
                    },
                },
                slots.default(),
            );
        };
    },
});

export const StripeElementsSubmitOnUpdate = defineComponent({
    name: 'StripeElementsSubmitOnUpdate',

    setup(_, { slots }) {
        const formWrap = ref(null);
        const paymentMethodId = ref(null);

        watchEffect(() => {
            if (!paymentMethodId.value || !formWrap.value) {
                return;
            }

            const form = formWrap.value.closest('form');

            if (form) {
                nextTick(() => {
                    form.submit();
                });
            }
        });

        return () => {
            return [
                h(
                    'div',
                    {
                        ref: formWrap,
                    },
                    slots.default({
                        paymentMethodId,
                    }),
                ),
                h('input', {
                    name: 'paymentForm[stripe][paymentMethodId]',
                    type: 'hidden',
                    value: paymentMethodId.value,
                }),
            ];
        };
    },
});
