<template>
    <nav v-if="pages > 1" class="mt-8">
        <ul class="pagination">
            <li class="pagination__item">
                <button
                    rel="prev"
                    class="pagination__button"
                    :disabled="current <= 1"
                    @click.prevent="$emit('changed', current - 1)"
                >
                    &lt;
                </button>
            </li>

            <li v-for="n in prevPages" :key="n" class="pagination__item">
                <button
                    rel="prev"
                    class="pagination__button"
                    :disabled="current === n"
                    @click.prevent="$emit('changed', n)"
                >
                    {{ n }}
                </button>
            </li>

            <li class="pagination__item pagination__item--current">
                <button
                    rel="prev"
                    class="pagination__button"
                    disabled
                    @click.prevent="$emit('changed', current)"
                >
                    {{ current }}
                </button>
            </li>

            <li v-for="n in nextPages" :key="n" class="pagination__item">
                <button
                    rel="prev"
                    class="pagination__button"
                    :disabled="current === n"
                    @click.prevent="$emit('changed', n)"
                >
                    {{ n }}
                </button>
            </li>

            <li class="pagination__item">
                <button
                    rel="prev"
                    class="pagination__button"
                    :disabled="current >= pages"
                    @click.prevent="$emit('changed', current + 1)"
                >
                    &gt;
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    props: {
        items: {
            type: Number,
            required: true,
        },
        itemsPerPage: {
            type: Number,
            required: true,
        },
        current: {
            type: Number,
            required: true,
        },
    },

    computed: {
        pages() {
            return Math.ceil(this.items / this.itemsPerPage);
        },
        prevPages() {
            const total = Math.max(this.current - 1, 0);
            const pages = Math.min(total, 5);

            const array = [];
            for (let i = 0; i < pages; ++i) {
                array.push(1 + i);
            }

            return array;
        },
        nextPages() {
            const total = this.pages - this.current;
            const pages = Math.min(total, 5);

            const array = [];
            for (let i = 0; i < pages; ++i) {
                array.unshift(this.pages - i);
            }

            return array;
        },
    },
};
</script>
