<script>
export default {
    props: {
        options: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            swiperOptions: {
                ...this.options,
                updateOnImagesReady: true,
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                lazy: {
                    preloaderClass: 'loading',
                    loadOnTransitionStart: true,
                },
            },
        };
    },

    computed: {
        swiper() {
            return this.$refs.gallery.swiper;
        },
    },
};
</script>
