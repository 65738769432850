export default class Calculator {
    constructor(
        tableField,
        valueCol,
        output,
        calculateRowTotal = false,
        fstsTable = false,
        costsWithComm = false
    ) {
        this.tableField = tableField;
        this.valueCol = valueCol;
        this.output = output;
        this.calculateRowTotal = calculateRowTotal;
        this.fstsTable = fstsTable;
        this.costsWithComm = costsWithComm;

        this.table = null;
        this.rows = [];
        this.outputField = null;
        this.calculationFields = [];
        this.total = 0;
        this.fstsCosts = null;
    }

    init() {
        this.table = this.tableField.querySelector('table');
        this.outputField = document.querySelector(
            `[data-fui-id=${this.output}]`
        );
        //this.outputField = document.getElementById(this.output);
        this.fstsCosts =
            this.tableField.closest('.fui-i').dataset.fstsCosts ?? null;
        this.fstsCosts = JSON.parse(this.fstsCosts);

        this.updateRows();
        this.updateFields();
        this.updateRemoveButtons();
        this.updateCalculationFields();
        this.updateFstsFields();
        this.updateOtherCostsValue();

        this.tableField.addEventListener('append', (e) => {
            this.updateRows();
            this.updateCalculationFields();
            this.updateRemoveButtons();
            this.updateFields();
            this.updateFstsFields();
            this.updateOtherCostsValue();
        });
    }

    updateRows() {
        this.rows = this.table.querySelectorAll('tbody tr');
    }

    updateFields() {
        let fields = this.table.querySelectorAll('input[type=number]');

        const listener = (e) => {
            this.calculateTotal(e);
            this.outputField.value = this.total;
        };

        const onChange = (e) => {
            this.calculateTotal(e);
            this.outputField.value = this.total;
        };

        fields.forEach((field) => {
            field.removeEventListener('blur', listener);
            field.addEventListener('input', listener);
        });
    }

    updateFstsFields() {
        if (!this.fstsTable) {
            return;
        }

        this.rows.forEach((row) => {
            const fstsCostCol = row.querySelector(
                'td[data-col-handle="functionalSpace"]'
            );

            const fstsInput = fstsCostCol.querySelector('input[type="text"]');

            if (fstsInput) {
                fstsInput.classList.add('hidden');
            }

            const selectInput = fstsCostCol.querySelector('select');

            if (selectInput) {
                return;
            }

            const fstsCostField = document.createElement('select');
            fstsCostField.id = 'fsts-cost';
            fstsCostField.classList.add('fui-select');
            fstsCostCol.appendChild(fstsCostField);

            fstsCostField.addEventListener('change', (event) => {
                this.calculateFstsTotalValue(event.target);
            });

            const optionElement = document.createElement('option');
            optionElement.value = '';
            optionElement.text = 'N/A';
            fstsCostField.appendChild(optionElement);

            this.fstsCosts.forEach((option) => {
                const optionElement = document.createElement('option');

                const value = JSON.stringify({
                    key: option.key,
                    cost: option.cost,
                });

                optionElement.value = value;
                optionElement.text = option.key;

                optionElement.selected = value === fstsInput.value;

                fstsCostField.appendChild(optionElement);
            });
        });
    }

    updateCalculationFields() {
        this.calculationFields = [];

        this.rows.forEach((row) => {
            let inputs = [];

            if (this.valueCol) {
                inputs = row.querySelectorAll(
                    `td[data-col-handle=${this.valueCol}] input[type=number]`
                );
            } else {
                inputs = row.querySelectorAll('input[type=number]');
            }

            if (!inputs.length) {
                return;
            }

            inputs.forEach((input) => {
                this.calculationFields.push(input);
            });
        });
    }

    updateRemoveButtons() {
        const buttons = this.table.querySelectorAll('.fui-table-remove-btn');

        const listener = (event) => {
            // setTimeout to ensure DOM is updated
            setTimeout(() => {
                this.updateRows();
                this.updateCalculationFields();
                this.calculateTotal();
                this.outputField.value = this.total;
            }, 100);
        };

        buttons.forEach((btn) => {
            // Remove all existing event listeners when rebinding
            btn.removeEventListener('click', listener);
            btn.addEventListener('click', listener);
        });
    }

    calculateRowTotalValue(input) {
        const tr = input.closest('tr');
        const inputs = tr.querySelectorAll('input[type=number]');
        let value = 0;

        inputs.forEach((input, index) => {
            if (input.value === '' || index === inputs.length - 1) {
                return;
            }
            value = value + parseInt(input.value);
        });

        const lastInput = inputs[inputs.length - 1];
        lastInput.value = value;
    }

    calculateFstsTotalValue(input) {
        const tr = input.closest('tr');
        const cost = tr.querySelector(
            'td[data-col-handle="functionalSpace"] select'
        ).value;
        const costInput = tr.querySelector(
            'td[data-col-handle="functionalSpace"] input[type="text"]'
        );
        costInput.value = cost;
        const amountInput = tr.querySelector(
            'td[data-col-handle="totalAreaSqm"] input[type="number"]'
        );
        // let totalInput = tr.querySelector(
        //     'td[data-col-handle="totalCost"] input[type="number"]'
        // );
        // const totalManualInput = tr.querySelector(
        //     'td[data-col-handle="totalCost"] input[type="number"]'
        // );
        const totalCostInput = tr.querySelector(
            'td[data-col-handle="totalCost"] input[type="number"]'
        );
        let costSqmInput = tr.querySelector(
            'td[data-col-handle="costSqm"] input[type="number"]'
        );
        const totalManualSqmInput = tr.querySelector(
            'td[data-col-handle="costSqm"] input[type="number"]'
        );
        const totalCostSqm = tr.querySelector(
            'td[data-col-handle="costSqm"] input[type="number"]'
        );

        if (
            this.costsWithComm &&
            costSqmInput &&
            totalCostSqm &&
            totalCostSqm.value
        ) {
            costSqmInput = tr.querySelector(
                'td[data-col-handle="commonwealthGuidelineTotal"] input[type="number"]'
            );

            totalManualSqmInput.value =
                Math.round(
                    (JSON.parse(totalCostInput.value) / amountInput.value) * 100
                ) / 100;
        }

        //     totalManualSqmInput.value =
        //         JSON.parse(totalCostSqm.value) * amountInput.value;
        // }

        try {
            costSqmInput.value =
                JSON.parse(costInput.value).cost * amountInput.value;
        } catch (exception) {
            console.log(exception);
        }
    }

    calculateTotal(event = null) {
        if (this.calculateRowTotal && event) {
            this.calculateRowTotalValue(event.target);
        }

        if (this.fstsTable && event) {
            this.calculateFstsTotalValue(event.target);
        }

        let total = 0;

        this.calculationFields.forEach((field) => {
            if (field.value === '') {
                return;
            }

            total = total + parseInt(field.value);
        });

        this.total = total;
    }

    updateOtherCostsValue() {
        const otherCostsTable = document.querySelector(
            'div[data-field-handle="estimateActualCostsAtTheTimeTheyWillBeIncurred"]'
        );
        const otherCostTotalField = document.querySelectorAll(
            'input[data-fui-id="capitalGrantApplicationForm1-totalOtherCosts"]'
        );
        otherCostsTable.addEventListener('change', (e) => {
            let otherCostsValue = 0;
            let otherCosts = document.querySelectorAll(
                'input[data-fui-id="capitalGrantApplicationForm1-estimateActualCostsAtTheTimeTheyWillBeIncurred-estimatedCost"]'
            );
            otherCosts.forEach((cost) => {
                otherCostsValue =
                    otherCostsValue +
                    Math.round(parseFloat(cost.value) * 100) / 100;
            });
            otherCostTotalField[0].value =
                Math.round(parseFloat(otherCostsValue) * 100) / 100;
        });
    }
}
