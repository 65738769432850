// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

import Calculator from './calculator';

// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp, provide, ref } from 'vue';

// Setup Apollo
import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client/core';

import { DefaultApolloClient } from '@vue/apollo-composable';

const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: '/api',
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
});

// Import all globally-available components here. Be responsible please.
import {
    AccordionItem,
    Gallery,
    MapboxMap,
    MobileMenu,
    SchoolsList,
} from '@components';

import {
    StripeElements,
    StripeElementsButton,
    StripeElementsCard,
    StripeElementsCardNumber,
    StripeElementsCvc,
    StripeElementsExpiry,
    StripeElementsSubmitOnUpdate,
} from '@components/StripeElements';

const app = createApp({
    delimiters: ['${', '}'],

    components: {
        AccordionItem,
        Gallery,
        MapboxMap,
        MobileMenu,
        SchoolsList,
        StripeElements,
        StripeElementsButton,
        StripeElementsCard,
        StripeElementsCardNumber,
        StripeElementsCvc,
        StripeElementsExpiry,
        StripeElementsSubmitOnUpdate,
    },

    setup() {
        provide(DefaultApolloClient, apolloClient);

        const navActive = ref(false);
        const offCanvas = ref(false);

        return {
            navActive,
            offCanvas,
        };
    },

    mounted() {
        // Escape key close menu
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape' && this.navActive === true) {
                this.navActive = false;
            }
        });

        // Filtering
        const replaceState = (params) => {
            if (params === '') {
                window.location.replace(
                    `${location.protocol}//${location.host}${location.pathname}`,
                );
                return;
            }

            window.location.replace(
                `${location.protocol}//${location.host}${location.pathname}?${params}`,
            );
        };

        const interestCategory = document.querySelector(
            '.js-interest-category',
        );

        if (interestCategory) {
            interestCategory.addEventListener('change', (e) => {
                let searchParams = new URLSearchParams(window.location.search);

                if (e.target.value == '') {
                    searchParams.delete('category');
                    replaceState(searchParams.toString());
                    return;
                }

                searchParams.set('category', e.target.value);

                replaceState(searchParams.toString());
            });
        }

        const locationCategory = document.querySelector(
            '.js-location-category',
        );

        if (locationCategory) {
            locationCategory.addEventListener('change', (e) => {
                let searchParams = new URLSearchParams(window.location.search);

                if (e.target.value == '') {
                    searchParams.delete('location');
                    replaceState(searchParams.toString());
                    return;
                }

                searchParams.set('location', e.target.value);

                replaceState(searchParams.toString());
            });
        }

        const monthCategory = document.querySelector('.js-month-category');

        if (monthCategory) {
            monthCategory.addEventListener('change', (e) => {
                let searchParams = new URLSearchParams(window.location.search);

                if (e.target.value == '') {
                    searchParams.delete('month');
                    replaceState(searchParams.toString());
                    return;
                }

                searchParams.set('month', e.target.value);

                replaceState(searchParams.toString());
            });
        }

        // Scroll to links

        const quickLink = document.querySelectorAll('.is-quick-link');

        if (quickLink.length) {
            document.addEventListener('click', (e) => {
                if (e.target.matches('.is-quick-link')) {
                    e.preventDefault();

                    const divId = document.getElementById(
                        e.target.getAttribute('href').substring(1),
                    );

                    divId.scrollIntoView({
                        behavior: 'smooth',
                    });
                }
            });
        }

        // Scroll to top

        const scrollToTop = document.getElementById('scroll-to-top');

        if (scrollToTop) {
            scrollToTop.addEventListener('click', (e) => {
                const topId = document.getElementById('app');
                topId.scrollIntoView({
                    behavior: 'smooth',
                });
            });
        }

        //Form Calculations
        const tables = document.querySelectorAll('.js-table-calculations');

        tables.forEach((table) => {
            const valueFieldHandles = table.dataset.valueFields.split(', ');
            const valueCols = table.dataset.valueColumns.split(', ');
            const calculators = [];

            valueCols.forEach((column, index) => {
                calculators.push({
                    valueColumn: column,
                    valueField: valueFieldHandles[index],
                });
            });

            let calculateRowTotal = false;

            if (table.dataset.calculateRowTotal) {
                // eslint-disable-next-line
                calculateRowTotal = table.dataset.calculateRowTotal;
            }

            const fstsTable = table.classList.contains('js-fsts-costs');

            const costsWithComm = table.classList.contains(
                'js-costs-with-commonwealth',
            );

            calculators.forEach((calculator) => {
                const tableCalculator = new Calculator(
                    table,
                    calculator.valueColumn,
                    calculator.valueField,
                    calculateRowTotal,
                    fstsTable,
                    costsWithComm,
                );
                tableCalculator.init();
            });
        });
    },
});

app.mount('#app');
